import React from "react";
import IsotopeData from "./IsotopeData";
import SidebarPortal from '@plone/volto/components/manage/Sidebar/SidebarPortal';

const IsotopeEdit = React.memo((props) => {
    const { selected, onChangeBlock, block, data } = props;

    return(
        <>
            <div id="filters" className="button-group filter-button-group">
                    <button className='filterBtn is-checked'>Alle</button>
                    {data.default_tags != undefined &&
                        data.default_tags.map((filter) => (
                            <button className='filterBtn'>{ filter.tagname }</button>
                        ))
                    }
            </div> 
            <SidebarPortal selected={selected}>
                <IsotopeData 
                {...props}
                data={data}
                block={block}
                onChangeBlock={onChangeBlock}
                />
            </SidebarPortal>
        </>
    )
})

export default IsotopeEdit;