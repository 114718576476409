import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchContent } from '@plone/volto/actions';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { format  } from 'date-fns'

const getNewsContent = (array = []) =>
  array.map((obj, item) => {
    obj[0] = item;
    return obj;
  }, {}
);

const NewsBoxView = (props) => {
  const data = props.data;
  const contentUrl = data.newsurl;
  const subrequestkey = props.block;

  const dispatch = useDispatch();
  const news = useSelector((state) => getNewsContent(state.search.subrequests[subrequestkey]?.items));
  const content = useSelector((state) => state.workflow.transition);

  var results = [];

  useEffect(() => {
      dispatch(
        searchContent(
          contentUrl,
          {
            portal_type: ['Event'],
            fullobjects: true, 
            b_size: 1000,
            sort_on: "start",
            sort_order: "descending",
          },
          subrequestkey,
        ),
      );
  },[dispatch, content, contentUrl]);

  news.forEach((elem) => {
    results.push([elem.title, format(new Date(elem.start), 'dd.MM.yyyy'), elem['preview_image']?.download, elem['@id'], elem.UID]);
  })

  console.log(news);

  return(
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid className='newsContainer' justifyContent="center" container item spacing={4}>
            {results.map((result) => (
              <Grid item className='gridItem' key={result[4]}>
                <a className='newsBoxA' href={result[3]}>
                  <div className="newsBox">
                    <div className="newsTop">
                      <div className="newsImg" style={{ backgroundImage: `url(${result[2]})` }}></div>
                      <svg xmlns="http://www.w3.org/2000/svg" className="newsSvg" viewBox="0 0 210.051 28.785">
                        <path d="m.001 29.03.018-1.983c.729-.008 24.531.373 35.78.282 15.811-.129 30.114-.113 45.118-1.22 15.439-1.14 30.813-3.171 46.106-5.574 16.23-2.55 32.433-5.452 48.386-9.373 9.159-2.25 18.174-5.081 27.107-8.107 2.48-.84 7.346-2.786 7.346-2.786l.19 28.784z"
                          style={{fillOpacity: 1, stroke: "none", strokeWidth: 0.154, strokeOpacity: 1,}} transform="translate(-.001 -.269)"
                        />
                      </svg>
                    </div>
                    <div className="newsBottom">
                      <p className="newsTitle">{result[0]}</p>
                      <p>{result[1]}</p>
                    </div>
                  </div>
                </a>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
);
};

NewsBoxView.propTypes = {
    data: PropTypes.objectOf(PropTypes.any),
};

export default NewsBoxView;